import { computed } from 'vue'

import useLogin from '#composables/use-login'

/**
 * Helper composition function to get account related information easily,
 * without having to import the auth composable.
 */
export default function useAccount () {
  const { account, accountId, userPayload, address } = useLogin()

  return {
    account,
    address,
    hasAccount: computed(() => !!account.value),
    id: computed(() => accountId.value),
    username: computed(() => account.value?.username),
    bio: computed(() => account.value?.bio),
    communities: computed(() => account.value?.communities),
    email: computed(() => account.value?.email),
    type: computed(() => account.value?.type),
    banner: computed(() => account.value?.banner),
    icon: computed(() => account.value?.icon),
    social: computed(() => account.value?.social),
    walletType: computed(() => account.value?.wallet),
    isGlobalNotificationsEnabled: computed(() => account.value?.notifications?.enabled),
    wallets: computed(() => account.value?.wallets),
    defaultWallet: computed(() => account.value?.defaultWallet),
    created: computed(() => account.value?.created),
    blocked: computed(() => !!account.value?.blocked),
    accountNotifications: computed(() => account.value?.notifications),
    reputationScore: computed(() => account.value?.reputation.score),

    twitter: computed(() => account.value?.social?.twitter),
    instagram: computed(() => account.value?.social?.instagram),
    website: computed(() => account.value?.social?.website),

    isArtist: computed(() => account.value?.type === 'artist'),

    isGenesisHolder: computed(() => !!userPayload.value?.genesisHolder),
    isAdmin: computed(() => account.value?.roles.includes('admin')),
    isCreator: computed(() => account.value?.roles.includes('creator')),
    isCollector: computed(() => account.value?.roles.includes('collector')),

    showroomId: computed(() => account.value?.showroomId),

    tokenCount: computed(() => account.value?.tokens?.length),
    verifiedType: computed(() => account.value?.profile?.verifiedType),
    verified: computed(() => !!account.value?.profile?.verified),
    verifiedRole: computed(() => account.value?.roles.includes('verified')),
    profileDisplayName: computed(() => account.value?.profile?.displayName),
    profileSlug: computed(() => account.value?.profile?.username),
    profileAvatar: computed(() => account.value?.profile?.icon),
    profileDescription: computed(() => account.value?.profile?.bio),
    profileUrl: computed(() => account.value?.profile?.website),
    profileUsername: computed(() => account.value?.profile?.username),
    hasFarcaster: computed(() => !!account.value?.profile?.fid),
    farcasterId: computed(() => account.value?.profile?.fid),
    hasTwitterProfile: computed(() => !!account.value?.profile?.twitterUsername && !!account.value?.profile?.twitterId),
    twitterUsername: computed(() => account.value?.profile?.twitterUsername),
    twitterId: computed(() => account.value?.profile?.twitterId),

    followerCount: computed(() => account.value?.profile?.followerCount),
    followingCount: computed(() => account.value?.profile?.followingCount),
  }
}
