<template>
  <p
    v-if="!inline"
    :class="[textSize, textColor]"
    v-bind="$attrs"
  >
    <slot />
  </p>

  <span
    v-else
    class="inline-block"
    :class="[textSize, textColor]"
    v-bind="$attrs"
  >
    <slot />
  </span>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { PropType } from 'vue'
import type { BodySize } from './types'

const props = defineProps({
  size: {
    type: String as PropType<BodySize>,
    default: 'base',
    validator: (value: BodySize) => ['base', 'full-base', 'xs','sm', 'lg', 'xl'].includes(value),
  },

  darkBackground: {
    type: Boolean,
    default: false,
  },

  inline: {
    type: Boolean,
    default: false,
  },

  whiteText: {
    type: Boolean,
    default: false,
  },

  blackText: {
    type: Boolean,
    default: false,
  },

  customTextColor: {
    type: String,
    default: '',
  },

  disabledText: {
    type: Boolean,
    default: false,
  },
})

const textSize = computed(() => {
  switch (props.size) {
    case 'base':
    default:
      return 'text-base md:text-base lg:text-xl'
    case 'full-base':
      return 'text-base'
    case 'xs':
      return 'text-xs md:text-xs lg:text-xs'
    case 'sm':
      return 'text-xs md:text-xs lg:text-base'
    case 'lg':
      return 'text-lg md:text-xl lg:text-2xl'
  }
})

const textColor = computed(() => {
  if (!!props.customTextColor && !props.disabledText) {
    return `text-[${props.customTextColor}]`
  }

  if (props.whiteText && !props.disabledText) {
    return 'text-white'
  }

  if (props.blackText && !props.disabledText) {
    return 'text-black'
  }

  if (!props.darkBackground && !props.disabledText) {
    return 'text-brand-copy-light'
  }

  if (props.disabledText) {
    return 'text-brand-disabled-copy'
  }

  return 'text-brand-border'
})
</script>
