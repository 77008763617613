<template>
  <div class="hidden md:flex w-full sticky top-24">
    <ul class="flex w-full flex-col gap-4 px-8">
      <li class="w-full">
        <SideNavItem
          icon="HomeIcon"
          text="Home"
          :fill-icon="$route.name === 'home'"
          :is-active="$route.name === 'home'"
          @click="navigateTo('home')"
        />
      </li>

      <li
        v-if="isAuthenticated"
        class="w-full"
      >
        <SideNavItem
          icon="DollarSignIcon"
          text="Earnings"
          :is-active="$route.name === 'earnings'"
          @click="navigateTo('me-earnings')"
        />
      </li>

      <li
        v-if="isAuthenticated"
        class="w-full"
      >
        <SideNavItem
          icon="PieChartIcon"
          text="Analytics"
          :is-active="$route.name === 'profile-analytics'"
          @click="navigateTo('me-analytics')"
        />
      </li>

      <li
        v-if="isAuthenticated"
        class="w-full"
      >
        <SideNavItem
          icon="BarChart2Icon"
          text="Leaderboard"
          :is-active="$route.name === 'leaderboard'"
          @click="navigateTo('leaderboard')"
        />
      </li>

      <li
        v-if="isAuthenticated"
        class="w-full"
      >
        <SideNavItem
          use-profile-icon
          :profile-icon="icon"
          text="Profile"
          :is-active="$route.name === 'profile-moments' && route.params.profileSlug === profileSlug"
          @click="navigateTo('profile-moments')"
        />
      </li>

      <li
        v-if="isAuthenticated"
        class="w-full mt-7"
      >
        <div class="space-y-2 w-full ml-2">
          <button
            class="bg-black text-white rounded-full w-12 h-12 flex items-center justify-center drop-shadow-lg"
            @click="handleCreateMoment"
          >
            <Icon
              icon="PlusIcon"
              class="w-5 h-5"
            />
          </button>
        </div>
      </li>
    </ul>

    <CreateMomentUnauthorizedModal
      :is-open="isCreateMomentUnauthorizedModalOpen"
      @close="isCreateMomentUnauthorizedModalOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import useLogin from '#composables/use-login'
import useAccount from '#composables/use-account'

import Icon from '#components/icon.vue'
import SideNavItem from '#components/home/side-nav-item.vue'
import CreateMomentUnauthorizedModal from '#components/global/create-moment-unauthorized-modal.vue'

const emit = defineEmits(['openDepositModal'])

const router = useRouter()
const route = useRoute()
const { isCreator, isAdmin, icon } = useAccount()
const { account, isAuthenticated } = useLogin()

const isCreateMomentUnauthorizedModalOpen = ref(false)
const profileMenuIsOpen = ref(false)

const profileSlug = computed(() => account.value?.slug || '')

const closeProfileMenu = () => profileMenuIsOpen.value = false

const navigateTo = (path: string) => {
  router.push({ name: path, params: { profileSlug: profileSlug.value || '' } })

  profileMenuIsOpen.value = false
}

const handleOpenDepositModal = () => {
  emit('openDepositModal')
}

const handleCollect2EarnApplication = () => {
  const collect2EarnApplicationUrl = `https://d674ncbpli8.typeform.com/to/DtsUCzH0`

  window.open(collect2EarnApplicationUrl, '_blank')
  profileMenuIsOpen.value = false
}

const navigateToAndCloseMenu = (path: string) => {
  navigateTo(path)

  closeProfileMenu()
}

const handleCreateMoment = () => {
  if (!isCreator.value && !isAdmin.value) {
    isCreateMomentUnauthorizedModalOpen.value = true
  } else {
    router.push({ name: 'moment-create', params: { profileSlug: account.value.slug || '' } })
  }
}
</script>
