
import { ref, computed, onMounted } from 'vue'

import { GET_BALANCES } from '#queries/GetBalances'
import type { GetBalances, GetBalancesVariables } from '#graphql/types/GetBalances'

import { useExchangePrices } from '#composables/use-exchange-prices'
import { useLazyQuery } from '#composables/use-apollo'
import useLogin from '#composables/use-login'

const { ethPrice } = useExchangePrices()

const {
  id,
  isAuthenticated,
} = useLogin()

// get balances
const {
  load: getBalances,
} = useLazyQuery<GetBalances, GetBalancesVariables>(GET_BALANCES)

const priceUsd = ref(0)
const priceEth = ref(0)

const currentBalanceUsd = computed(() => priceUsd.value)
const currentBalanceEth = computed(() => priceEth.value)

export function useBalance () {
  const waitForBalance = async () => {
    try {
      const userId = id.value
      if (!userId || !isAuthenticated.value) {
        return
      }
      const getBalancesResult = await getBalances({ input: { id: userId, chainId: parseInt(import.meta.env.VITE_DEFAULT_CHAIN_ID) } }, { fetchPolicy: 'no-cache' })
      if (getBalancesResult.data?.getBalances.balances) {
        const wei = getBalancesResult?.data?.getBalances.balances[0].balance
        const ethExchangeRate = ethPrice.value
        const dollars = wei * ethExchangeRate

        priceEth.value = Math.round(wei * 100000) / 100000

        priceUsd.value = Math.round(dollars * 100) / 100
      }
    } catch (error) {
      console.error(error)
    }
  }

  waitForBalance()

  return {
    currentBalanceEth,
    currentBalanceUsd,
    waitForBalance,
  }
}
