<template>
  <div class="flex flex-col w-full h-32 lg:h-48 rounded-md justify-between">
    <button
      v-if="showArrow"
      class="absolute translate-x-0 translate-y-0"
      @click="emit('back')"
    >
      <Icon
        icon="ArrowLeftIcon"
        class="transform transition-transform text-black"
      />
    </button>

    <div class="flex flex-col w-full h-full content-center justify-between">
      <div class="text-4xl text-black font-semibold text-center pt-6 lg:pt-10">
        {{ `$${price.toFixed(2)} USD` }}
      </div>

      <div class="w-full h-10 lg:h-14">
        <CryptoPrice
          :chain-id="chainId"
          :price="price"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { type PropType } from 'vue'

import Icon from '#components/icon.vue'
import CryptoPrice from '#components/minting/crypto-price.vue'

const emit = defineEmits(['back'])

defineProps({
  price: {
    type: Number,
    default: 0,
  },
  chainId: {
    type: Object as PropType<any | null>,
    default: null,
  },
  showArrow: {
    type: Boolean,
    default: true,
  },
})
</script>
