import { gql } from '@apollo/client/core'

/**
 * Gets a user's account.
 */
export const GET_ACCOUNT = gql`
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      slug
      username
      bio
      email
      banner
      icon
      type
      created
      blocked
      defaultWallet
      wallets {
        address
        label
        type
        connectionType
      }
      wallet
      roles
      notifications {
        enabled
        emailMigrationMessage
        settingsMigrationMessage
        loginMigrationMessage
        reward
        join
        claim
        comment
        mentions
        tip
        reaction
        daily
        farcaster {
          addToAccountDismissed
          shareProfileDismissed
        }
        xProfileDismissed
        collectableMomentsUpdateDismissed
      }
      social {
        twitter
        instagram
        website
      }
      verified
      verifiedType
      showroomId
      showroom {
        id
      }
      profile {
        accountId
        id
        displayName
        username
        icon
        banner
        website
        bio
        followerCount
        followingCount
        collectorCount
        isFollowing
        dropCount
        momentCount
        verified
        fid
        twitterId
        twitterUsername
        verifiedType
      }
      tokens {
        id
        collectionId
        accountId
      }
      reputation {
        score
      }
    }
  }
`
