import { gql } from '@apollo/client/core'

/**
 * Gets all (paginated) collections.
 */
export const SEARCH_ACCOUNTS_BY_USERNAME = gql`
  query SearchAccountsByUsername($input: SearchAccountsByUsernameInput!) {
    searchAccountsByUsername(input: $input) {
      accounts {
        id
        username
        icon
        slug
        profileId
        email
      }
    }
  }
`
